<template>
  <section class="section section-standalone" id="404">
    <div class="container-fluid">
      <div class="my-3">
        <div class="c-dashboard-title d-flex justify-content-between">
          <h1 class="title-font text-size-title text-uppercase mb-0">
            Halaman tidak ditemukan
          </h1>
          <div class="c-dashboard-title-option d-flex align-items-center">
            <button class="btn btn-danger mr-2" v-on:click="$router.go(-1)">
              <i class="fa fa-arrow-left"></i> Kembali
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'NotFound'
};
</script>
